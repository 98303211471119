import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import PuffLoader from "react-spinners/PuffLoader";
import { Icon } from "semantic-ui-react";
import {
  setUserDetails,
  setAccessToken,
  setUserName,
  setAccess,
  setRefresh,
} from "../../redux/redux/global/action";
import axios from "../../utils/axios";
import { ACC_LOCKED } from "../../utils/labelContant";

const LoginAsClient = (props) => {
  useEffect(() => {
    return () => {

    }
  }, [props])
  let history = useHistory();
  const dispatch = useDispatch();
  const [clientEmailError, setClientEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formValue, setFormValue] = useState({
    // role: 4,
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const [classHeader, setClassHeader] = useState("");
  const [btnSubmitted, setBtnSubmitted] = useState(false);
  const onChange = (e) => {
    setPasswordError("");
    setClientEmailError("");
    setFormValue({ ...formValue, [e.target.id]: e.target.value });
  };

  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validate = () => {
    var clientEmail = formValue.email;
    var clientPassword = formValue.password;

    if (clientEmail === "" || clientPassword === "") {
      if (clientEmail === "") {
        setClientEmailError("Please Enter Email Address");
      }
      if (clientPassword === "") {
        setPasswordError("Please enter password");
      }
    } else if (!validateEmail(clientEmail)) {
      setClientEmailError("Please enter a valid email address ");
    }
    else {
      // props.onNext()
      // props.formvalue(formValue)
      return login();
    }
  };

  const resetError = () => {
    setError(null);
    setClientEmailError("");
    setPasswordError("");
  };
  const loginTypeValue = () => {
    if (props.title === "Client Login") {
      return { role: 4 };
    }
    return {};
  };
  const login = () => {
    let value = { ...formValue, ...loginTypeValue() };
    setBtnSubmitted(true);
    axios
      .post("/login", value, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          let resData = response.data.result
          localStorage.setItem("authToken", resData.token);
          if (resData.securitycode) {
            dispatch(setAccess(resData.permissions));
            dispatch(setRefresh(true));
            props.onNext("SECURITY_VERIFICATION")
            props.formvalue(formValue)
            props.email(formValue.email)
          }else{
            props.onNext("SECURITY")
            props.formvalue(formValue)
            props.email(formValue.email)
          }
        }
      })
      .catch((er) => {
        if(er?.response?.data?.result?.dot_access == false){
            setError(ACC_LOCKED);
        }
        else{
          setError("Invalid Credential");
        }
      })
      .finally(() => {
        setBtnSubmitted(false);
      });
  };
  const activateKey = (event) => {
    if(event.key === 'Enter'){
      validate();
    }
  }
  useEffect(() => {
    if (props.title === "Team Login") {
      setClassHeader("-blue")
    } else {
      setClassHeader("")
    }
    resetError();
  }, [props.title]);

  return (
    <div className="client-login" id="client_login">
      <p className={`login-input-header` + classHeader}>{props.title}</p>
      <div className="errorLabel">{error}</div>
      <div className={`input-form-control` + classHeader}>
        <label className="input-form-control-label" htmlFor="email">
          Email Address
        </label>
        <input
          placeholder=""
          className="input-form-control-input"
          type="email"
          autoComplete="off"
          aria-autocomplete="none"
          id="email"
          onChange={onChange}
          onFocus={resetError}
        />
        <div className="errorLabel">{clientEmailError}</div>
      </div>
      <div className={`input-form-control` + classHeader}>
        <label className="input-form-control-label" htmlFor="password">
          Password
        </label>
        <input
          placeholder=""
          className="input-form-control-input"
          type="password"
          autoComplete="none"
          id="password"
          onKeyPress={activateKey}
          onChange={onChange}
          onFocus={resetError}
        />
        <div className="errorLabel">{passwordError}</div>
      </div>
      <div className={`input-form-control` + classHeader}>
        <label
          className="input-form-control-label-forgot-password"
          onClick={() => props.onForgoPassword("FORGOT_PASSWORD")}
        >
          Forgot Password ?
        </label>
      </div>
      <button
        className="login-type-submit"
        style={{
          height: window.innerHeight / 12,
          width: window.innerHeight / 12,
        }}
        onClick={() =>
          validate()
        }
        disabled={btnSubmitted}   
      >
        {btnSubmitted ? (
          <PuffLoader color={"#FFFFFF"} loading={true} size={40} />
        ) : (
          <Icon name={"arrow right"} size="large" />
        )}
        
      </button>
    </div>
  );
};

export default LoginAsClient;
