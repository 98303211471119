import moment from "moment";
export const SELECT_EMPLOYEE = "Select Employee";
export const FROM_DATE = "FROM DATE";
export const TO_DATE = "TO DATE";
export const LEAVE_REJECTED = "Leave Rejected";
export const WORK_TYPE = "Work Type";
export const START_TIME = "Start Time";
export const END_TIME = "End Time";
export const PROJECT = "Project";
export const JOB_DESCRIPTION = "Job Description";
export const DATE = "Date";
export const SHIFT = "Shift";
export const DAY = "Day";
export const SHIFT_IN = "Shift In";
export const SHIFT_OUT = "Shift Out";
export const EXTRA_HOURS = "Extra Hours";
export const TOTAL_HOURS = "Total Hours";
export const LATE_BY = "Late By";
export const EARLY_BY = "Early By";
export const STATUS = "Status";
export const DETAILS = "Details";
export const SHIFT_LABEL = "SHIFT";

export const PAGELIMIT = 5;
export const CREATE_JOB = "Create New Jobs";
export const VAT = "Vat Created Successfully";
export const LAST_WORKING_DATE = "Last Working Date";
export const REASON_OF_RESIGNATION = "Reason of Resignation";
export const TOTAL_YR_OF_SERVICE = "Total Yr of Service";
export const NAME_AS_PER_PANCARD = "Name as per Pancard";
export const NAME_AS_PER_ADHARCARD = "Name as per Aadhar Card";
export const PERMENENT_ADDRESS = "Permanent Address";
export const PERSONAL_CONTACT = "Personal Contact";
export const SR_NO = "Sr No.";
export const QUALIFICATION1 = "Qualification 1";
export const QUALIFICATION2 = "Qualification 2";
export const QUALIFICATION3 = "Qualification 3";
export const REPORTING_MANAGER1 = "Reporting Manager 1";
export const REPORTING_MANAGER2 = "Reporting Manager 2";
export const REPORTING_MANAGER_DESIGNATION2 = "Reporting Manager Designation2";
export const REPORTING_MANAGER_DESIGNATION1 = "Reporting Manager Designation1";
// Management Report
export const COMPANYNAME = "company name";
export const COMPANYTITLE = "Company Name Ascending/Descending";

export const limit = 10;
export const MONTH = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const SR = "Sr.No.";
export const EMP_NAME = "Employee Name";
export const BILLED_HOURS = "Billed Hours";
export const TOTAL = "Total";
export const page = 10;
export const SUBMIT = "Submit";
export const TEAM = "Team";
export const YEAR = "Year";

export const CATCH_TOAST = "Something went wrong, Please try again later";
export const PERFORMANCE_SUCCESS = "Performance Report Updated Successfully";
export const STAFF_UPDATE_TOAST = "Staff Updated Successfully";
export const REQUIRE_DATA_TOAST = "Please Enter all required Data";
export const SALARY_DELETE_TOAST = "Salary Deleted with Job Id:";
export const SALARY_DELETE_MODAL_TOAST =
  "Are you sure you want to delete Salary with id:";
export const EDUCATION_DETAILS_TOAST = "Please Enter All Education Details";
export const CREATE_INVOICE_TOAST = "No Jobs for Create Invoice";
export const INVOICE_TOAST = "Sent Invoice Successfully";
export const DELETE_INVOICE_TOAST = "Invoice Deleted Successfully";
export const INVOICE_CREATED_TOAST = "Invoice has been Created";
export const INTERNAL_SERVER_ERROR = "Internal Server Error";
export const FILL_DATA = "Please fill all data";
export const PDF_DATA = "Pdf's are not allowed";
export const SELECT_CLIENT = "Please Select a Client";
export const MAX_LIMIT = "Maximum limit 3";
export const JOB_TYPE = "Job Type";
export const CANDIDATE_TOAST = "Candidate Created Successfully";
export const SOMETHING_WRONG_TOAST = "Something Went Wrong";
export const TIMEKEEPER_TOAST = "Time Keeper Data updated successfully.";
export const TIMEKEEPER_CREATE_TOAST = "Time Keeper Data created successfully.";
export const LEAVE_TOAST = "Leave Application cancelled successfully.";
export const PLANNING_TOAST = "Planing activity added Successfully";
export const CURRENCY_TOAST = "Currency activity added Successfully";
export const ATTENDANCE_TOAST = "Attendance Type added Successfully";
export const COUNTRY_TOAST = "Country activity added Successfully";
export const PLANING_TOAST = "Planing activity update Successfully";
export const CURRENCY_UPDATE_TOAST = "Currency activity update Successfully";
export const COUNTRY_UPDATE_TOAST = "Country activity update Successfully";
export const ATTENDANCE_UPDATE_TOAST = "Attendance Type update Successfully";
export const PLANNING_DELETE_TOAST = "Planing activity deleted Successfully";
export const CURRENCY_DELETE_TOAST = "Currency deleted Successfully";
export const COUNTRY_DELETE_TOAST = "Country deleted Successfully";
export const ATTENDANCE_DELETE_TOAST = "Attendance Type deleted Successfully";
export const PRIORITY_TOAST = "Priority Added Successfully";
export const DEPARTMENT_TOAST = "Department Added Successfully";
export const GRADE_TOAST = "Grade Added Successfully";
export const JOBTYPE_TOAST = "Jobtype Added Successfully";
export const TAT_TOAST = "Tat Added Successfully";
export const PRIORITY_UPDATE_TOAST = "Priority Updated Successfully";
export const VAT_UPDATE_TOAST = "Vat Updated Successfully";
export const TAT_UPDATE_TOAST = "Tat Updated Successfully";
export const DEPARTMENT_UPDATE_TOAST = "Department Updated Successfully";
export const GRADE_UPDATE_TOAST = "Grade Updated Successfully";
export const JOBTYPE_UPDATE_TOAST = "Jobtype Updated Successfully";
export const PRIORITY_DELETE_TOAST = "Priority Deleted successfully";
export const VAT_DELETE_TOAST = "Vat Deleted successfully";
export const TAT_DELETE_TOAST = "Tat Deleted successfully";
export const DEPARTMENT_DELETE_TOAST = "Department Deleted successfully";
export const GRADE_DELETE_TOAST = "Grade Deleted successfully";
export const JOBTYPE_DELETE_TOAST = "Jobtype Deleted successfully";
export const TEAM_TOAST = "Team Added Successfully";
export const PAY_TOAST = "PayComponent Added Successfully";
export const PAY_UPDATE_TOAST = "PayComponent Updated Successfully";
export const PAY_DELETE_TOAST = "PayComponent Deleted successfully";
export const ROLE_UPDATE_TOAST = "Role Updated Successfully";
export const ROLE_DELETE_TOAST = "Role Deleted successfully";
export const ROLE_TOAST = "Role Added Successfully";
export const TRAINING_TOPIC_TOAST = "Training Topic Added Successfully";
export const RECRUITMENT_SOURCE_TOAST = "Recruitment Source Added Successfully";
export const RECRUITMENT_UPDATE_TOAST = "Recruitment Updated Successfully";
export const RECRUITMENT_DELETE_TOAST = "Recruitment Deleted Successfully";
export const TRAINING_UPDATE_TOAST = "Training Updated Successfully";
export const TRAINING_DELETE_TOAST = "Training Deleted successfully ";
export const LEAVE_CREATE_TOAST = "Leave Type Added Successfully";
export const LEAVE_DELETE_TOAST = "Leave Type Deleted successfully ";
export const HOLIDAY_TOAST = "Holiday Type Added Successfully";
export const HOLIDAY_UPDATE_TOAST = "Holiday Type Updated Successfully";
export const HOLIDAY_DELETE_TOAST = "Holiday Type Deleted successfully ";
export const SHIFT_TOAST = "Shift Type Added Successfully";
export const SHIFT_UPDATE_TOAST = "Shift Type Updated Successfully";
export const SHIFT_DELETE_TOAST = "Shift Type Deleted successfully ";
export const CANDIDATE_STATUS_TOAST = "Candidate Status Added Successfully";
export const CANDIDATE_STATUS_UPDATE_TOAST =
  "Candidate Status Updated Successfully";
export const CANDIDATE_STATUS_DELETE_TOAST =
  "Candidate Status Deleted successfully ";
export const SKILL_STATUS_TOAST = "Skills Category Added Successfully";
export const SKILL_STATUS_UPDATE_TOAST = "Skills Category Updated Successfully";
export const SKILL_STATUS_DELETE_TOAST =
  "Skills Category Deleted successfully ";
export const LEAVE_TYPE_TOAST = "Leave Type Added Successfully";
export const KEY_MILESTONE_TOAST = "Key Milestone Deleted Successfully";
export const EXPORT_SUCCESSFULLY = "Export Successfully";
export const Export_FAILED = "Export Failed";
export const INTERNAL_SERVER = "internalServerError";
export const MANDATORY_FIELD = "Please enter all mandatory fields";
export const FILE_UPLOAD_SUCCESS = "File Uploaded Successfully";
export const FILE_UPLOAD_SUCCESS_ID = "fileUploadedSuccessfully";
export const NEW_OTP = "A new OTP has been sent to your email.";
export const ACC_LOCKED = "Your account is locked due to multiple failed attempts. Contact support at Hello@thedothq.com or your Dot Account Manager.";

export const AVERAGE = "Average";
export const DATE_OF_JOINING = "DATE OF JOINING";
export const MONTHLY_CTC = "MONTHLY CTC";
export const ANNUAL_CTC = "ANNUAL CTC";
export const UPDATE_STAFF = "Update Staff";
export const ADD_NEW_STAFF = "Add New Staff";
export const JOINING_KIT = "Joining Kit";
export const JOINING = "joining_kit";
export const EXIT_MODULE = "exit_module";
export const EXIT_DOCUMENT = "Exit Document";
export const RESUME = "Resume";

export const DOJ = "DOJ";
export const DOB = "DOB";
export const BLOOD_GROOP = "Blood Group";
export const DUE_DATE = "Due Date";
export const EMP_CODE = "Emp Code";
export const EMP_STATUS = "Employee Status";
export const FATHER_NAME = "Father Name";
export const EMERGENCY_CONTACT_RELATIONSHIP = "Emergency Contact RelationShIP";
export const OFFER_LETTER = "Offer Letter";
export const CHOOSE_FILE = "Choose File";
export const DOCUMENT_NAME = "Document Name";

export const EMPLOYEE_CODE = "EMPLOYEE CODE";
export const EMPLOYEE_NAME = "EMPLOYEE NAME";

export const Father_Name = "FATHER NAME";
export const Date_of_Birth = "DATE OF BIRTH";
export const Blood_Group = "BLOOD GROUP";
export const Designation = "DESIGNATION";
export const Grade = "GRADE/LEVEL";
export const DEPARTMENT = "DEPARTMENT";
export const RECRUITMENT_SOURCE_STAFF = "RECRUITMENT SOURCE";
export const CONTACT_NUMBER = "CONTACT NUMBER";
export const EMERGENCY_CONTACT = "EMERGENCY CONTACT";
export const RELATIONSHIP_WITH = "RELATIONSHIP WITH";
export const PERSONAL_EMAIL = "PERSONAL EMAIL";
export const OFFICIAL_EMAIL = "OFFICIAL EMAIL";
export const MARITAL_STATUS = "MARITAL STATUS";
export const GENDER = "GENDER";
export const SPOUSE_NAME = "SPOUSE NAME";
export const ADDRESS = "ADDRESS";
export const SELECT_MANAGER = "Select Manager";
export const ERROR_MESSAGE = "errorMessage";
export const SUCCESS_MESSAGE = "successMessage";

export const TYPE = "Type";
export const CATEGORY = "Category";
export const CLIENT = "Client";
export const FROM_TIME = "From Time";
export const TO_TIME = "To Time";
export const DESCRIPTION = "Description";
export const MEMBER_NAME = "Member Name";
export const HOURS = "Hours";
export const DOT_MEMBER_CHART = "DotMemberChart";
export const REVENUE_CHART = "RevenueChart";
export const MONTH_REVENUE = "MonthRevenue";
export const TIMEKEEPING = "TimeKeeping";
export const TIME_ACTUAL_BILL = "TimeVsActualBill";
export const PRODUCTIVITY_MARK = "ProductivityMark";
export const STAFF_LEAVE_PLANNER = "StaffLeavePlanner";
export const SOW_IN_PROGRESS = "SowInProgress";
export const SOW_DISPATCHED = "SowDispatched";
export const VAT_YEAR_END = "VatYearEnd";
export const QUOTATION_TIMELINE = "QuotationTimeline";
export const BAR_CHART = "BarChart";
export const MONTHLY_ATTENDANCE = "MonthlyAttendance";
export const TRAINING_REPORT = "TrainingReport";
export const SLA_REMINDER = "SlaReminder";
export const TAT = "TAT";
export const CLIENT_FEEDBACK = "ClientFeedback";
export const TOP_PERFORMERS = "TopPerformers";
export const NO = "No";
export const YES = "Yes";
export const PREVIEW = "Preview";
export const VIEW_INVOICE = "View Invoice";
export const CREATE_INVOICE = "Create Invoice";
export const EMPLOYEE_NAMES = "employee_name";
export const EMPLOYEE_DETAILS = "Employee Details";
export const NAME = "Name";
export const DESIGNATION = "designation";
export const DESIGNATION_TITLE = "Designation";
export const EXP = "Exp";
export const Years_Months = "Years/Months";
export const AGE = "Age";
export const STAFF_NAME = "staff_name";
export const MEMBERS = "Members";
export const MANAGER = "manager";
export const MANAGER_TITLE = "Manager";
export const TOTAL_ID = "total";

export const MANAGER_NAME = "manager_name";
export const BILLABLE_HOURS = "billable_Hours";
export const BILLABLE_HR = "Billable_Hours";
export const BILLABLE_HRS = "Billable Hrs";
export const BILLED_HRS = "billed_Hours";
export const BILLED_HRS_TITLE = "Billed Hrs";
export const APPROVED_TIME = "Approved Time";
export const TEAM_MANAGEMENT = "Team Management";
export const INTERNAL_QUERY_SOLVING = "Internal Query Solving";
export const NO_WORK = "No Work";
export const IT_ISSUES = "IT Issues";
export const TIMEKEEPING_ID = "Timekeeping";
export const MEETING_DISCUSSION = "Meeting & Discussion";
export const TRAINING = "Training";
export const KOB = "KOB & Process update";
export const CLIENT_SERVICING = "Client Servicing";
export const TOTAL_OTHERS = "Total Others";
export const PERCENT = "%";
export const JOB_ID = "Job Id";
export const JOB_TITLE_ID = "Job ID";
export const JOB_REFERENCENO = "Job Referenceno";
export const JOB_REFERENCE_NO = "Job Reference No.";
export const COMPANY_NAME = "Company Name";
export const CLIENT_NAME = "Client Name";
export const BUDGET = "Budget";
export const ACTUAL = "Actual";
export const JOBS_ID = "jobs_id";
export const JOB_REF_NO = "job_referenceno";
export const COMPANY_NAMES = "company_name";
export const CLIENT_NAMES = "client_name";
export const MONTHS = "Months";
export const MONTH_TITLE = "Month";
export const BUDGET_ID = "budget";
export const FEES = "Fees";
export const STATUS_ID = "status";
export const STATUS_TITLE = "Status";
export const JOB_TYPE_ID = "job_type";
export const JOB_TYPE_TITLE = "Job Type";
export const JOB_DATE = "job_date";
export const JOB_RECEIVED_DATE = "Job Received Date";
export const QUOTATION_DATE = "quotation_Date";
export const QUOTE_SENT_DATE = "Quote Sent Date";
export const JOB_END_DATE = "job_end_date";
export const DEADLINE_DATE = "Deadline Date";
export const DISPATCH_DATE = "dispatch_date";
export const DISPATCH_DATE_ID = "Dispatch Date";
export const JOB_IDS = "job_id";
export const SCORE_ID = "score";
export const SCORE = "Score";
export const DATE_SCORE_TITLE = "Date of Score";
export const DATE_SCORE = "date_score";
export const CANDIDATE_ID = "CANDIDATE NAME";
export const CANDIDATE_DESIGNATION = "CURRENT DESIGNATION";
export const LOCATION = "LOCATION";
export const CURRENT_COMPANY = "CURRENT COMPANY";
export const CURRENT_SALARY = "CURRENT SALARY";
export const EXPECTED_SALARY = "EXPECTED SALARY";
export const NOTICE_PERIOD = "NOTICE PERIOD";
export const REASON_FOR_CHANGE = "REASON FOR CHANGE";
export const PHONE_NUMBER = "PHONE NUMBER";
export const EMAIL_ADDRESS = "EMAIL ADDRESS";
export const EDUCATION1 = "Education Qualification 1";
export const EDUCATION2 = "Education Qualification 2";
export const WORK_EXPERIENCE_1 = "Work Experience 1";
export const START_DATE = "START DATE";
export const END_DATE = "END DATE";
export const CURRENT_SALARY_ANNUAL = "CURRENT SALARY(ANNUAL)";
export const WORK_EXPERIENCE_2 = "Work Experience 2";
export const SELECTION_PROGRESS = "Selection Progress";
export const ATTACH_DOCUMENT = "Attached Document";
export const COMPANY_NAME_LABEL = "COMPANY NAME";
export const UPLOAD_DOCUMENT = "Upload Document";
export const FIRST_HALF_PL = "First Half PL";
export const SECOND_HALF_PL = "Second Half PL";
export const SECOND_HALF_CL = "Second Half CL";
export const FIRST_HALF_CL = "First Half CL";
//
export const EMPCODE = "Employee Code";
export const EMPNAME = "Employee Name";
//

export const TOT = "Employee Name";
export const JAN = "Jan";
export const FEB = "Feb";
export const MAR = "Mar";
export const APR = "Apr";
export const MAY = "May";
export const JUN = "Jun";
export const JUL = "Jul";
export const AUG = "Aug";
export const SEP = "Sep";
export const OCT = "Oct";
export const NOV = "Nov";
export const DEC = "Dec";

export const CANDIDATE_ID_TITLE = "Candidate Name";
export const CANDIDATE_DESIGNATION_TITLE = "Current Designation";
export const LOCATION_TITLE = "Location";
export const CURRENT_COMPANY_TITLE = "Current Company";
export const CURRENT_SALARY_TITLE = "Current Salary";
export const EXPECTED_SALARY_TITLE = "Expected Salary";
export const NOTICE_PERIOD_TITLE = "Notice Period";
export const REASON_FOR_CHANGE_TITLE = "Reason for Change";
export const PHONE_NUMBER_TITLE = "Phone Number";
export const EMAIL_ADDRESS_TITLE = "Email Address";
export const START_DATE_TITLE = "Start Date";
export const END_DATE_TITLE = "End Date";
export const CURRENT_SALARY_ANNUAL_TITLE = "Current Salary(Annual)";
export const SELECTION_PROGRESS_TITLE = "Selection Progress";
export const CREATE_INVOICE_TITLE = "Create Invoice";
export const UPDATE_INVOICE = "Update Invoice";
export const VAT_TITLE = "VAT";
export const AMOUNT = "Amount";
export const SELECT = "Select";
export const CLIENT_TYPE = "Client Type";
export const ADDITIONAL_CHARGES = "Additional Charges";
export const CREDIT = "Credit Note";
export const ADDITIONAL = "additional_charges";
export const CREDIT_NOTES = "credit_notes";
export const edit_invoice = "Edit Invoice Successfully";
export const AMOUNT_TITLE = "AMOUNT ";
export const SECTION = "Section";
export const INVOICE_DATE = "Invoice Date";
export const Pro = "Pro";
export const YEAREND = "YearEnd";
export const BOOKKEEPING = "Bookkeeping";
export const PAYROLL = "Payroll";
export const TAXATION = "Taxation";
export const AUDIT = "Audit";
export const TAS = "TAS";
export const MISC = "Misc";
export const SME = "SME";
export const SAVED = "Saved";
export const CREATED = "Created";
export const DATE_FORMAT = "D MMM YYYY";
export const DATE_FORMAT1 = "DD-MM-YYYY";
export const DATE_FORMAT_YEAR = "YYYY-MM-DD";
export const POSITION = "POSITION";
export const POSITION_TITLE = "Position";
export const SUB_TOTAL = "Sub Total";
export const TIME_KEEPER = "Time Keeper";
export const SAVE = "Save";
export const JOB_STATUS_LABEL = "JOB STATUS";
export const JOB_STATUS = "Job Status";
export const ATTENDANCE_TYPE_LABEL = "ATTENDANCE TYPE";

export const ACCESS_UPDATE = "Access Updated Successfully";
export const ACCESS_SUCCESSFULLY = "accessSuccessfully";
export const MEETING_ADDED_ERROR = "meetingAddedError";
export const YEAREND_DATE = "yearend_date";
export const EMAIL = "email";
export const JOB_STATUS_ID = "job_status";
export const CREATEDBY_NAME = "createdby_name";
export const DISPATCH = "dispatchDate";
export const ATTENDANCE = "Attendance";
export const FROM = "from";
export const FRM_DATE = "From Date";
export const TO_DATE_ID = "To Date";
export const GRADE = "grade";
export const PROFILE = "Profile";
export const ATTENDANCE_TYPE = "Attendance Type";
export const WEEKLY_OFF_LABEL = "WEEKLY OFF";
export const WEEKLY_OFF = "Weekly Off";
export const TIMEZONE_LABEL = "TIMEZONE";
export const TIMEZONE = "TimeZone";
export const NAME_OF_EMPLOYEE_LABEL = "NAME OF EMPLOYEE";
export const NAME_OF_EMPLOYEE = "Name of Employee";
export const STAFF = "STAFF";
export const MANAGER_LABEL = "MANAGER";
export const TIME = "hh:mm a";
export const TIME_FORMAT = "HH:mm:ss";
export const ADD_ATTENDANCE_TYPE = "Add Attendance Type";
export const ATTENDANCE_CODE = "Attendance Code";
export const EDIT = "edit";
export const UPDATE = "UPDATE";
export const ADD = "ADD";
export const CANCEL = "Cancel";
export const STAFF_ID = "staff_id";
export const MANAGER_ID = "manager_id";
export const ACCEPTED = "ACCEPTED";
export const CANCELLED = "CANCELLED";
export const FINANCIAL_YEAR_LABEL = "FINANCIAL YEAR";
export const FINANCIAL_YEAR = "Financial Year";
export const DEPARTMENT_LABEL = "Department";
export const CREATED_AT = "createdAt";
export const APPLIED_ON = "Applied On";
export const START_DATE1 = "start_date";
export const LEAVE_FROM = "Leave From";
export const END_DATE_LABEL = "end_date";
export const LEAVE_TO = "Leave To";
export const DURATION = "duration";
export const NO_OF_DAYS = "No Of Days";
export const LEAVE_STATUS = "Leave Status";
export const APPROVED_BY = "approved_by";
export const APPROVED_BY_TITLE = "Approved By";
export const APPROVED_ON = "approved_on";
export const APPROVED_ON_TITLE = "Approved On";
export const ID = "id";
export const CANCELLATIONS = "Cancellations";
export const EXPORT = "Export";
export const Dot_Kris_Access = "Dot Kris Access";
export const Employee_Code = "Employee Code";
export const CITY = "City";
export const STATE = "STATE/COUNTRY";
export const ZIP_CODE = "Zip Code";
export const COUNTRY = "Country";
export const Name_Pancard = "Name as per Pancard";
export const PANCARD_NUMBER = "Pancard Number";
export const NAME_AADHAR = "NAME AS PER AADHAR CARD";
export const AADHAR_NUMBER = "Aadhar Card Number";
export const UNIVERSITY = "University";
export const REPORTING_MANAGER_FOR_DOT = "REPORTING MANAGER FOR DOT";
export const REPORTING_MANAGER_NAME = "Reporting Manager Name";
export const SAVE_NEXT = "Save & Next";
export const DEGREE = "Degree";
export const GRADE_LABEL = "Grade/Level";
export const STATE_PLACEHOLDER = "State/Country";
export const REPORTING_MANAGER = "Reporting Manager";
export const REPORTING_MANAGER_DESIGNATION = "Reporting Manager Designation";
export const DESIGNATION_REPORT_MANAGER1 = "designation_report_manager1";
export const rmanager = "rmanager";
export const VIEW = "View";
export const LINK = "https://dotkrisfilesbucket.s3.eu-west-1.amazonaws.com/";
export const DOWNLOAD = "Download";
export const ACTIVE = "ACTIVE";
export const C_NAME = "c_name";
export const AVAILABLE = "Available Amount is ";
export const CREDIT_LEAVE = "Credit Leave";
export const Date_FROM = "Date From";
export const DATE_TO = "Date to";
export const REASON_FOR_WORKING = "Reason for Working";
export const REASON = "reason";
export const REQUIRED_END_DATE = "End Date is required";
export const REQUIRED_START_DATE = "Start Date is required";
export const REQUIRED_REASON = "Reason is required";
export const JOBID = "jobId";
export const REFERENCE_NO = "reference_no";
export const REF_NO = "refNo";
export const COMPANY = "company";
export const CNAME = "cname";
export const RECURRENCE_JOBS = "Recurrence Jobs";
export const COMPLETED = "% Completed";
export const JOB_STATUS_PERC = "job_status_perc";
export const JOBTYPE = "jobType";
export const YEAR_END_DATE = "Year End Date";
export const CREATED_BY = "createdBy";
export const CREATE_BY = "Created By";
export const JOB_CREATION_DATE = "Job Creation Date";
export const JOB_START_DATE = "job_start_date";
export const JOB_TAT = "job_tat";
export const DAYS_LEFT = "days_left";
export const Day_Left = "Days Left";
export const JOB_MANAGER_DOT = "Job Manager (Dot)";
export const RECURSION_STATUS = "recursion_status";
export const RECURRENCE_STATUS = "Recurrence Status";
export const OVERDUE = "Overdue";
export const DAYS = "Days";
export const NOT_APPLICABLE = "N/A";
export const NOT_APPLICABLE_DATE = "0000-00-00";
export const ARCHIVE_JOBS = "Archive Jobs";
export const CLIENT_ADDED_ERROR = "clientAddedError";
export const EXPORT_SUCCESS = "exportsuccess";
export const EXPORT_FAILURE = "exportfailure";
export const LEAVE_TYPE_ID = "leave_type_id";
export const LEAVE_TYPE = "leave_type";
export const INVOICE_NUMBER = "Invoice Number ";
export const REFERENCE = "Reference";
export const VAT_NUMBER = "VAT Number";
export const NUMBER = 369790045;
export const CONFIRMATION_DATE = "Confirmation Date";
export const T = "T";
export const RECURRENCE_DATE = "Recurrence Date";
export const RECURSION_DATE = "recursion_date";
export const BALANCE_DATE = " - Balance as on Date";
export const SOMETHING_WRONG = "SomethingWrong";
export const S3_RETRIEVE_FILE = "Could not retrieve file from S3: ";
export const DASH = "-";
export const ZERO = "0";
export const DASHBOARD = "Dashboard";
export const JOBS = "Jobs";
export const READ = "READ";
export const INVOICING = "Invoicing";
export const HR = "HR";
export const CREATE = "CREATE";
export const MY_ACCOUNT = "MyAccount";
export const MASTER = "Master";
export const REPORTS = "Reports";
export const PARTNER = "Partner";
export const DIRECTOR = "Director";
export const CLIENT_MANAGER = "Client Manager";
export const ACCOUNTANT = "Accountant";
export const MY_ACC = "My Account";
export const PENDING = "PENDING";
export const SPOUSE = "spouse";
export const ROLE = "role";
export const REPORT_MANAGER1 = "report_manager1";
export const BLOOD = "blood";
export const CONFIRMED = "CONFIRMED";
export const CONFIRMED_DATE = "Confirmation Date is required.";
export const CONFIRM_DATE = "confirmed_date";
export const DATA_NOT_FOUND = "Data Not Found";
export const JOBS_STATUS = "job_status";
export const BOTH = "Both";
export const MALE = "Male";
export const FEMALE = "Female";
export const ROLES = "Roles";
export const HOURLY_RATE = "Hourly Rate";
export const INVOICE_AMOUNT = "Invoice Amount";
export const ARCHIE_JOB_EXPORT = "Archie_Job_Export_";
export const JOB = "Job";
export const BREAK = "Break";
export const TEMPLATE = "template";
export const MANUALLY = "manually";
export const RECURRENCE = "Recurrence";
export const N_A = "Not Applicable";
export const CANDIDATE = "/candidates";
export const CURRENT_CMPNY = "current_company";
export const PHONE = "phone";
export const CURRENT_DESIGNATION = "current_designation";
export const WORK_EXPERIENCES = "work_experiences";
export const QUALIFICATIONS = "qualifications";
export const CURR_SALARY = "current_salary";
export const EXPECT_SALARY = "expected_salary";
export const NOT_PERIOD = "notice_period";
export const CURRENCY_NAME = "Currency Name";
export const CURRENCY_NAME_LABEL = "Currency_name";
export const BANK_NAME = "Bank Name";
export const BANK_NAME_LABEL = "bank_name";
export const ACCOUNT_NUMBER = "Account Number";
export const ACCOUNT_NUMBER_LABEL = "account_number";
export const IBAN = "IBAN";
export const IBAN_LABEL = "iban";
export const LWD = "LWD";
export const OPEN = "OPEN";

export const CURRENCY_CODE = "Currency Code";
export const CURRENCY_CODE_LABEL = "Currency_code";
export const BANK_ACCOUNT_NAME = "Bank Account Name";
export const BANK_ACCOUNT_NAME_LABEL = "bank_account_name";
export const SORT_CODE = "Sort Code";
export const SORT_CODE_LABEL = "sortcode";
export const DUE_DAYS = "Due in 15 Days";
export const ONLINE_TRANSFER = "Please pay by online transfer to : ";
export const COLON = " : ";
export const DELETE_NOTE = "Note Deleted Successfully";
export const HR_LABEL = "HR >";
export const CREATOR_DETAILS = "Creator Details : ";
export const USER_ID = "user_id";
export const ADD_STAFF = "addStaff";
export const SERVICES = "Services";
export const AVERAGE_TAT = "Average TAT";
export const MONTH_REVENUES = "Month Revenue";
export const WIP = "WIP";
export const DISPATCHED = "Dispatch";
export const REVENUE_BY_COUNTRY = "Revenue By Country";
export const REVENUE_TOP_CLIENT = "Revenue as per client";
export const REVENUE = "Revenue";
export const TOP_CLIENT = "Top Client";
export const AVG_TAT = "AverageTAT";
export const REVENUE_COUNTRY = "RevenueByCountry";
export const REVENUE_STREAM = "Revenue Stream";
export const AVERAGE_DAYS_CLIENTS = "Average Days of Clients";
export const UK = "UK";
export const IRELAND = "Ireland";
export const CANADA = "Canada";

export const DATE_OF_CONFIRMATION = "Date of Confirmation";
export const CONFIRMATION_STATUS = "Confirmation Status";
export const RESET = "Reset";
export const JOB_ASSIGNED = "Job Assigned";
export const IN_PROGRESS = "In Progress";
export const ON_HOLD = "On Hold";
export const QUERY_SENT_TO_CLIENT = "Query sent to client";
export const QUERY_CHANGES = "Query Changes";
export const UNDER_REVIEW = "Under Review";
export const REVIEW_CHANGE = "Review Change";
export const MISSING_INFO = "Missing Info";
export const JOB_STARTED = "Job Started";
export const QUOTE_APPROVED = "Quote Approved";
export const D_LEFT = "daysleft";
export const JOB_MANAGER = "jobManager";
export const GRADES = "Grade";
export const PREVIOUS = "Previous";
export const NEXT = "Next";
export const HOD = "HOD";
export const DONE = "DONE";
export const MATERNITY_LEAVE = "Maternity Leave";
export const TOTAL_LEAVE = "Total Leave";
export const LEAVES_AVAILABLE = "Leaves Available";
export const LEAVES_AVAILED = "Leaves Availed";
export const LEAVES_LAPSED = "Leaves Lapsed";
export const LEAVE_APPLICATIONS = "leaveApplications";
export const MARRIED = "Married";
export const UNMARRIED = "Unmarried";
export const DIVORCED = "Divorced";
export const MISC_ADHOC = "Misc - Adhoc";
export const USER_IDS = "&userid=";
export const JOB_ERROR_ID = "JobInvalidData";
export const FILE_NOT_UPLOAD="File does not uploaded successfully";
export const S3_BUCKET = "dotkrisfilesbucket";
export const ORIGINAL = "Original";
export const FILE_ERROR_ID = "fileAddedError";
export const AVERAGE_MONTH = "Average of Months";
export const PDF = ".pdf";
export const NULL = "NULL";
export const MONTHLY = "Monthly";
export const BI_MONTHLY = "Bi-monthly";
export const QUARTERLY = "Quarterly";
export const FORTNIGHTLY = "Fortnightly";
export const WEEKLY = "Weekly";
export const ANNUALLY = "Annually";
export const FOUR_WEEKLY = "Four weekly";
export const VERIFY_OTP = "Verify OTP";
export const RESEND_OTP = "Resend OTP";

export const Access_DOT_KRIS = "Give Access to DOT KRIS";

export const DATE_OF_RESIGNATION = "Date of Resignation";
export const TOTAL_ACCOUNTS_SOLUTIONS = "TAS - Total Accounts Solutions";
export const DOTKRIS_PROD_BUCKET = "dotkris-prod-static-assets";
export const DOTKRIS_PRE_BUCKET = "dotkris-pre-prod-static-assets";
export const DOTKRIS_DEV_BUCKET = "dotkris-dev-static-assets";
export const AWS_REGION = "eu-west-2";

export const LEAVES_TYPE = "Leave Type";
export const FULL_DAY = "Full Day";
export const FIRST_HALF = "1st Half";
export const TOTAL_LEAVE_COUNT = "Total Leave Count";
export const HOW_TO_USE = "How to use dotKris";
export const PL_P = "PL.P";
export const P_PL = "P.PL";
export const P_CL = "P.CL";
export const CL_P = "CL.P";
export const FIRST_HALF_PRESENT_WITH_PL = "First Half Present with PL";
export const SECOND_HALF_PRESENT_WITH_PL = "Second Half Present with PL";
export const FIRST_HALF_PRESENT_WITH_CL = "First Half Present with CL";
export const SECOND_HALF_PRESENT_WITH_CL = "Second Half Present with CL";
export const LOGOUT_ERROR = "An error occurred during logout. Please try again.";





// TOAST CONSTANTS

export const REGULARIZATION_TOAST = "Please Enter the Reason";

// api endpoint constants

export const METADATA_URL = "/hr/hrm/staff-metadata/";
export const TIMESHEET_OTHER_URL =
  "/staff/timesheet/fetchClientJobs?type=Other";
export const TIMESHEET_JOB_URL = "/staff/timesheet/fetchClientJobs";
export const DELETE_KEY_MILESTONE_URL = "staff/deletekeyMilestones";
export const SAVE_NOTES_URL = "/staff/saveNotes";
export const CANDIDATE_SUBMISSION_URL = "/staff/saveNotes";
export const DELETE_NOTE_URL = "/staff/deleteNotes";
export const SAVE_NOTE_URL = "/staff/fetchNotes?user_id=";
export const LEAVE_APPLICATIONS_URL = "/hr/hrm/leave-applications/list";
export const LEAVE_LIST_APPLICATIONS_URL = "/hr/hrm/leave-applications/leave-list";
export const LOGOUT = "/logout";
export const SEND_VERIFICATIONCODE = "/forgetPassword/sendVerificationCode";

export const STAFF_URL = "/staff";

export const PROJECT_SEARCH_DROPDOWN = "dds/jsCompanylist";
export function dateFormat(date) {
  return moment(date).format("DD-MM-YYYY");
}
export const MONTH_LABELS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const JOB_STATUS_COUNT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]

export const FILE_NAME_LIST = ["use_dotkris/1)_dotkris_login.mp4", "use_dotkris/2)_dotKris_Dashboard.mp4","use_dotkris/3)_Job_Creation.mp4","use_dotkris/4)_Quote_Approval.mp4","use_dotkris/5)_Journey_of_the_Job.mp4"]

export const BUCKET_FOLDER = {
  JOB_DOC: "job_doc[]",
  CLIENT_DOC: "client_doc[]",
  CANDIDATE_RESUME: "candidate_resume[]",
  STAFF_TRAINING: "staff_training[]",
  INVOICE_DOC: "invoice_doc[]",
  KEY_MILESTONES: "key_milestones[]",
  STAFF_DOCS: "staff_docs[]",
  TEMPLATE_IMAGES: "template_images[]",
  PICTURE:"picture[]",
  USE_KRIS: "use_dotkris"
  
}
export const REPORTS_MONTH = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const JOB_TYPE_LABELS = [
  "PODS/ Dotpro",
  "Year End",
  "Bookkeeping & Dotcast",
  "Payroll",
  "Taxation",
  "Audit",
  "TAS",
  "Misc",
];
export const JOB_LABELS = [
  "PODS/Dotpro",
  "YE",
  "BK/Dotcast",
  "PR",
  "Tax",
  "Audit",
  "TAS",
  "Misc",
];

//DROPDOWNS

export const TYPE_NAMES = [
  { jobType: "DOT PRO – seat model" },
  { jobType: "Year end" },
  { jobType: "Bookkeeping and Dotcast" },
  { jobType: "Payroll" },
  { jobType: "Taxation" },
  { jobType: "Audit" },
  { jobType: "TAS - Total Accounts Solutions" },
  { jobType: "Misc - Adhoc" },
  { jobType: "Bookkeeping and Dotcast" },
];
export const jobTat = [
  { key: "MIN", value: "MIN", text: "MIN", icon: "caret up" },
  { key: "MAX", value: "MAX", text: "MAX", icon: "caret down" },
];
export const RECURRENCE_OPTION = [
  { key: "Original", text: "Original", value: "Original" },
  { key: "Duplicate", text: "Duplicate", value: "Duplicate" },
  { key: "Stopped", text: "Stopped", value: "Stopped" },
];

export const progressOption = [
  { key: "Rejected", text: "Rejected", value: "Rejected" },
  { key: "On_Hold", text: "On Hold", value: "On Hold" },
  { key: "Shortlisted", text: "Shortlisted", value: "Shortlisted" },
  {
    key: "To_be_Interviewed",
    text: "To be Interviewed",
    value: "To be Interviewed",
  },
  {
    key: "Send_Link_for_Assessment",
    text: "Send Link for Assessment",
    value: "Send Link for Assessment",
  },
  { key: "Interview-1", text: "Interview - 1", value: "Interview - 1" },
  { key: "Interview-2", text: "Interview - 2", value: "Interview - 2" },
  { key: "Interview-3", text: "Interview - 3", value: "Interview - 3" },
  {
    key: "Interviewed_Rejected",
    text: "Interviewed & Rejected",
    value: "Interviewed & Rejected",
  },
  {
    key: "Interviewed_on Hold",
    text: "Interviewed & on Hold",
    value: "Interviewed & on Hold",
  },
  {
    key: "Interviewed_Shortlisted",
    text: "Interviewed & Shortlisted",
    value: "Interviewed & Shortlisted",
  },
  { key: "To_be_Offered", text: "To be Offered", value: "To be Offered" },
  { key: "Offered", text: "Offered", value: "Offered" },
  { key: "Offer_Sent", text: "Offer Sent", value: "Offer Sent" },
  { key: "Accepted ", text: "Accepted ", value: "Accepted " },
  { key: "Offer_Rejected", text: "Offer Rejected", value: "Offer Rejected" },
  { key: "To_join", text: "To join", value: "To join" },
  {
    key: "Move_to_Onboarding",
    text: "Move to Onboarding",
    value: "Move to Onboarding",
  },
];
export const noticeOption = [
  { key: "Immediate", text: "Immediate", value: "Immediate" },
  { key: "None", text: "None", value: "None Hold" },
  { key: "15Days", text: "15 Days", value: "15 Days" },
  { key: "30Days", text: "30 Days", value: "30 Days be Interviewed" },
  { key: "45Days", text: "45 Days", value: "45 Days Link for Assessment" },
  { key: "60Days", text: "60 Days", value: "60 Days" },
  { key: "90Days", text: "90 Days", value: "90 Days" },
  { key: "Interview-3", text: "Interview - 3", value: "Interview - 3" },
];
export const MODAL_DELETE = "Are you sure you want to delete invoice ?";

export const LEAVE_TYPES = {
  WEEKOFF_LEAVE: "Week Off",
  HOLIDAY: "Holiday",
  LEAVE_WITHOUT_PAY: "Loss Of Pay",
  CREDIT_LEAVE: "Credit Leave",
  EARNED_LEAVE: "Earned Leave",
  PRIVILEGE_LEAVE: "Privileged Leave",
  MATERNITY_LEAVE: "Maternity Leave",
  ABSENT: "Absent",
  REGULARIZATION: "Regularization",
  PL_P:"PL_P",
  P_PL :"P.PL",
  P_CL : "P.CL",
  CL_P : "CL.P",

};

export const ADDRESS_DATABASE = "Address Database";
export const EDUCATION = "Education";
export const REPORTING = "Reporting";
export const CONFIRMATION = "Confirmation";
export const EXIT = "Exit";

export const DOT_HEADER = [
  { label: "Name", key: "employee_name" },
  { label: "Designation", key: "designation" },
  { label: "Years/Months", key: "Exp" },
  { label: "Age", key: "Age" },
];

export const REVENUE_HEADER = [
  { label: "Name", key: "employee_name" },
  { label: "Designation", key: "designation" },
  { label: "Years", key: "doj" },
  { label: "Age", key: "Age" },
];

export const HEADER_VAT_YEAR_END = [
  { label: "Job Id", key: "jobs_id" },
  { label: "Job Reference No.", key: "job_referenceno" },
  { label: "Company Name", key: "company_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Month", key: "Months" },
  { label: "Budget", key: "budget" },
  { label: "Fees", key: "Fees" },
];
export const HEADER_EMPLOYEE_MASTER_DATABASE = [
  { label: "Sr No.", key: "sr_no" },
  { label: "Employee Code", key: "employee_id" },
  { label: "Employee Full Name", key: "employee_name" },
  { label: "Employee Designation", key: "designation" },
  { label: "Employee Department", key: "department" },
  { label: "Employee Status", key: "emp_status" },
  { label: "Grade", key: "grade" },
  { label: "Gender", key: "gender" },
  { label: "Employee DOJ", key: "doj" },
  { label: "Employee DOB", key: "dob" },
  { label: "Marital Status", key: "marital_status" },
  { label: "Fathers Name", key: "father_name" },
  { label: "Spouse Name", key: "spouse_name" },
  { label: "Blood Group", key: "blood_group" },
  { label: "Name as per Pancard", key: "pan_card_name" },
  { label: "Pancard Number", key: "pan_card_no" },
  { label: "Name as per Aadhar Card", key: "aadhar_card_name" },
  { label: "Aadhar Card Number", key: "aadhar_card_no" },
  { label: "Dot Access", key: "dot_access" },
  { label: "Permanent Address", key: "address" },
  { label: "Personal Contact", key: "phone" },
  { label: "Email Address", key: "email" },
  { label: "Emergency Contact", key: "emergency_contact" },
  {
    label: "Emergency Contact Relationship",
    key: "relationship",
  },

  { label: "Qualification 1", key: "qualification1" },
  { label: "Qualification 2", key: "qualification2" },
  { label: "Qualification 3", key: "qualification3" },
  { label: "Reporting Manager 1", key: "reporting_manager_One" },
  {
    label: "Reporting Manager 1 Designation ",
    key: "report_manager_one_designation",
  },
  { label: "Reporting Manager 2", key: "reporting_manager_Two" },
  {
    label: "Reporting Manager 2 Designation ",
    key: "report_manager_two_designation",
  },
  { label: "Due Date", key: "due_date" },
  { label: "Date of Confirmation", key: "date_of_confirmation" },
  { label: "Confirmation Status", key: "confirmation_status" },
  { label: "Date of Resignation", key: "date_of_resignation" },
  { label: "Last Working Date", key: "last_working_date" },
  { label: "Reason of Resignation", key: "reason_of_resignation" },
  { label: "Total Year of Service", key: "total_yr_of_service" },
];
export const HEADER_PRODUCTIVITY_MARK = [
  { label: "Members", key: "Members" },
  { label: "Manager", key: "Manager" },
  { label: "Approved Time", key: "Approved Time" },
  { label: "Billable Hrs", key: "Billable Hrs" },
  { label: "Team Management", key: "Team Management" },
  { label: "Internal Query Solving", key: "Internal Query Solving" },
  { label: "No Work", key: "No Work" },
  { label: "IT Issues", key: "IT Issues" },
  { label: "Timekeeping", key: "Timekeeping" },
  { label: "Meeting  & Discussion", key: "Meeting & Discussion" },
  { label: "Training", key: "Training" },
  { label: "KOB Process update", key: "KOB & Process update" },
  { label: "Client Servicing", key: "Client Servicing" },
  { label: "Total Others", key: "Total Others" },
  { label: "%", key: "%" },
];

export const HEADER_STAFF_LEAVE_PLANNER = [
  { label: "Sr No.", key: "sr_no" },
  { label: "Emp Code", key: "staff_details.employee_id" },
  { label: "Employee Name", key: "staff_details.employee_name" },
  { label: "Designation", key: "staff_details.designation" },
  { label: "Department", key: "staff_details.department" },
  { label: "DOJ", key: "staff_details.doj" },
  { label: "Grade", key: "staff_details.grade" },
  { label: "Emp Status", key: "status" },
  { label: "LWD", key: "lwd" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
  { label: "01-Mar-22", key: "01-Mar-22" },
];
export const HEADER_MONTHLY_ATTENDANCE = [
  { label: "Sr No.", key: "sr_no" },
  { label: "Emp Code", key: "employee_id" },
  { label: "Employee Name", key: "employee_name" },
  { label: "Designation", key: "designation" },
  { label: "Department", key: "department" },
  { label: "DOJ", key: "doj" },
  { label: "Grade", key: "grade" },
  { label: "Emp Status", key: "employee_status" },
  { label: "LWD", key: "lwd" },
];
export const HEADER_TIME_ACTUAL_BILL = [
  { label: "Members", key: "employee_name" },
  { label: "Manager", key: "manager_name" },
  { label: "Billable Hrs", key: "Billable_Hours" },
  { label: "Billed Hrs", key: "billed_Hours" },
  { label: "Efficiency", key: "efficiency" },
];

export const HEADER_TIME_KEEPING = [
  { label: "Members", key: "staff_name" },
  { label: "Manager", key: "manager" },
];

export const HEADER_SOW_IN_PROGRESS = [
  { label: "Job Id", key: "Job Id" },
  { label: "Job Reference No.", key: "Job Referenceno" },
  { label: "Company Name", key: "Company Name" },
  { label: "Client Name", key: "Client Name" },
  { label: "Job Type", key: "job_type" },
  { label: "Budget", key: "Budget" },
  { label: "Actual", key: "Actual" },
];
export const HEADER_MONTH_REVENUE = [
  { label: "Job Id", key: "job_id" },
  { label: "Job Type", key: "job_type" },
  { label: "Job Reference No", key: "reference_no" },
  { label: "Company Name", key: "company_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Status", key: "status" },
  { label: "Fee", key: "Fee" },

];

export const HEADER_AVERAGE_TAT = [
  { label: "Job Type", key: "job_name" },
  { label: "Average Days of Clients", key: "TAT" },
];
export const HEADER_TOP_PERFORMERS = [
  { label: "Name", key: "name" },
  { label: "January", key: "January" },
  { label: "February", key: "February" },
  { label: "March", key: "March" },
  { label: "April", key: "April" },
  { label: "May", key: "May" },
  { label: "June", key: "June" },
  { label: "July", key: "July" },
  { label: "August", key: "August" },
  { label: "September", key: "September" },
  { label: "October", key: "October" },
  { label: "November", key: "November" },
  { label: "December", key: "December" },
  { label: "Total", key: "count" },
];

export const HEADER_SOW_DISPATCH = [
  { label: "Job Id", key: "Job Id" },
  { label: "Job Reference No.", key: "Job Referenceno" },
  { label: "Company Name", key: "Company Name" },
  { label: "Client Name", key: "Client Name" },
  { label: "Job Type", key: "Job Type" },
  { label: "Budget", key: "Budget" },
  { label: "Actual", key: "Actual" },
];
export const HEADER_QUOTATION_TIMELINE = [
  { label: "Job ID", key: "jobs_id" },
  { label: "Job Reference No.", key: "job_referenceno" },
  { label: "Company Name", key: "company_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Status", key: "status" },
  { label: "Job Type", key: "job_type" },
  { label: "Job Received Date", key: "job_date" },
  { label: "Quote Sent Date", key: "quotation_Date" },
];
export const HEADER_CLIENT_FEEDBACK = [
  { label: "Job ID", key: "job_id" },
  { label: "Job Reference No.", key: "job_referenceno" },
  { label: "Company Name", key: "company_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Job Type", key: "job_type" },
  { label: "Score", key: "score" },
  { label: "Date of Score", key: "date_score" },
];
export const HEADER_TAT = [
  { label: "Job ID", key: "jobs_id" },
  { label: "Job Reference No.", key: "job_referenceno" },
  { label: "Company Name", key: "company_name" },
  { label: "Client Name", key: "client_name" },
  { label: "Status", key: "status" },
  { label: "Job Type", key: "job_type" },
  { label: "Deadline Date", key: "job_end_date" },
  { label: "Dispatch Date", key: "dispatch_date" },
  { label: "TAT", key: "TAT" },
];

export const HEADER_ATTRITION_RATE = [
  { label: "Sr No", key: "sr_no" },
  { label: "Emp Code", key: "emp_code" },
  { label: "Employee Name", key: "employee_name" },
  { label: "Designation", key: "designation" },
  { label: "Department", key: "department" },
  { label: "EMP DOJ", key: "date_joining" },
  { label: "Date of Resignation", key: "date_reg" },
  { label: "Last Working Date", key: "lwd" },
  { label: "Reason of Resignation", key: "reason" },
  { label: "Total Yr of Service", key: "Exp" },
];

export const HEADER_REVENUE_PER_CLIENT = [
  { label: "Company Name", key: "company_name" },
  { label: "January", key: "January" },
  { label: "February", key: "February" },
  { label: "March", key: "March" },
  { label: "April", key: "April" },
  { label: "May", key: "May" },
  { label: "June", key: "June" },
  { label: "July", key: "July" },
  { label: "August", key: "August" },
  { label: "September", key: "September" },
  { label: "October", key: "October" },
  { label: "November", key: "November" },
  { label: "December", key: "December" },
  { label: "Total", key: "count" },
];
export const HEADER_REVENUE_BY_COUNTRY = [
  { label: "Country Name", key: "Country_Name" },
  { label: "Revenue", key: "Revenue" },
];

export const HEADER_REVENUE_STREAM = [
  { label: "Services", key: "job_name" },
  { label: "January", key: "January" },
  { label: "February", key: "February" },
  { label: "March", key: "March" },
  { label: "April", key: "April" },
  { label: "May", key: "May" },
  { label: "June", key: "June" },
  { label: "July", key: "July" },
  { label: "August", key: "August" },
  { label: "September", key: "September" },
  { label: "October", key: "October" },
  { label: "November", key: "November" },
  { label: "December", key: "December" },
  { label: "Total", key: "count" },
];

export const HEADER_TRAINING_REPORT = [
  { label: "Emp Code", key: "employee_id" },
  { label: "Employee Name", key: "employee_name" },
  { label: "January", key: "January" },
  { label: "February", key: "February" },
  { label: "March", key: "March" },
  { label: "April", key: "April" },
  { label: "May", key: "May" },
  { label: "June", key: "June" },
  { label: "July", key: "July" },
  { label: "August", key: "August" },
  { label: "September", key: "September" },
  { label: "October", key: "October" },
  { label: "November", key: "November" },
  { label: "December", key: "December" },
  { label: "Total", key: "count" },
];
export const JOB_STATUS_OPTIONS = [
  {
    text: "Probation",
    value: "PROBATION",
  },
  {
    text: "Confirmed",
    value: "CONFIRMED",
  },
];

export const WEEK_OFF_DATA = [
  { text: "Saturday", value: "Saturday" },
  { text: "Sunday", value: "Sunday" },
];
export const RECURRENCE_HEADERS = [
  { label: "Job Id", key: "id" },
  { label: "Job Ref. No.", key: "reference_no" },
  { label: "Company Name", key: "company" },
  { label: "Client Name", key: "client" },
  { label: "% Complete", key: "job_status_perc" },
  { label: "Job Type", key: "job_type" },
  { label: "Year End Date", key: "yearend" },
  { label: "Job Status", key: "job_status" },
  { label: "Budget", key: "budget" },
  { label: "Actual", key: "actual" },
  { label: "Created By", key: "CREATE_BY" },
  { label: "Job Creation Date", key: "job_start_date" },
  { label: "Deadline Date", key: "job_end_date" },
  { label: "Dispatch Date", key: "dispatch_date" },
  { label: "TAT", key: "job_tat" },
  { label: "Days Left", key: "days_left" },
  { label: "Job Manager (Dot)", key: "manager" },
];

export const HEADER_PERFORMANCE = [
  { label: "Employee", key: "employee_name" },
  { label: "January", key: "January" },
  { label: "February", key: "February" },
  { label: "March", key: "March" },
  { label: "April", key: "April" },
  { label: "May", key: "May" },
  { label: "June", key: "June" },
  { label: "July", key: "July" },
  { label: "August", key: "August" },
  { label: "September", key: "September" },
  { label: "October", key: "October" },
  { label: "November", key: "November" },
  { label: "December", key: "December" },
  { label: "Total", key: "count" },
];
export const TotalModulesList = [
  { moduleName: "Jobs" },
  { moduleName: "Quotation" },
  { moduleName: "Client" },
  { moduleName: "Invoicing" },
  { moduleName: "Staff" },
  { moduleName: "MyAccount" },
  { moduleName: "Access" },
  { moduleName: "Master" },
  { moduleName: "Reports > Client" },
  { moduleName: "Reports > Operations" },
  { moduleName: "Reports > Management" },
  { moduleName: "Reports > HR" },
  { moduleName: "Archive Jobs" },
  { moduleName: "Recurrence Jobs" },
  { moduleName: "HR > Recruitment" },
  { moduleName: "HR > Timekeeper" },
  { moduleName: "HR > Templates" },
  { moduleName: "HR > Candidates" },
  { moduleName: "HR > Onboarding" },
  { moduleName: "HR > Timekeeper > Leaves" },
  { moduleName: "HR > Timekeeper > Attendance" },
  { moduleName: "Staff > Training > Create Training" },
  { moduleName: "Staff > Training > Dot Skill" },
  { moduleName: "Staff > Training > Skill Logs" },
  { moduleName: "Staff > Performance Reports" },
  { moduleName: "Staff > Exit" },
];

export const COMPANY_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "company_name", title: "Company Name", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
  { id: "updatedAt", title: "Updated At", classStyle: {} },
];
export const ROLES_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "name", title: "Role", classStyle: {} },
  // { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const PRIORITY_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "priority", title: "Priority", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
  { id: "updatedAt", title: "Updated At", classStyle: {} },
];
export const STATUS_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "status", title: "Status", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
  { id: "updatedAt", title: "Updated At", classStyle: {} },
];
export const COMPONENT_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "pay_components", title: "Name", classStyle: {} },
  { id: "type", title: "Type", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const ACTIVITY_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "activity_name", title: "Planing Activity", classStyle: {} },
  { id: "job_type", title: "Job Type", classStyle: {} },
  { id: "createdBy", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const CURRENCY_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "currency_name", title: "Currency Name", classStyle: {} },
  { id: "currency_code", title: "Currency Code", classStyle: {} },
  { id: "createdBy", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const COUNTRY_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "name", title: "Country Name", classStyle: {} },
  { id: "short_code", title: "Country Code", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const TRAINING_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "training", title: "Training", classStyle: {} },
  // { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];

export const VAT_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "vat", title: "Vat", classStyle: {} },
  { id: "createdBy", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];

export const RECRUITMENT_SOURCE = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "recruitment_source", title: "Recruitment Source", classStyle: {} },
  { id: "isActive", title: "Is Active", classStyle: {} },
  { id: "createdBy", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
]

export const ATTENDANCETYPE_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "type", title: "Attendance Type", classStyle: {} },
  { id: "short_code", title: "Attendance Code", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const LEAVETYPE_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "type", title: "Leave Type", classStyle: {} },
  { id: "short_code", title: "Code", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];

export const HOLIDAYTYPE_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "name", title: "Holiday Type", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const SHIFTTYPE_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "start_Time", title: "Start Time", classStyle: {} },
  { id: "end_Time", title: "End Time", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const CANDIDATESTATUS_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "status", title: "Status", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const SKILLSCATEGORY_CALL = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "category", title: "Category", classStyle: {} },
  { id: "createdBy", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const DEPARTMENT_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "name", title: "Department", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const GRADE_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "name", title: "Grade", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const JOBTYPE_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "name", title: "Jobtype", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const TAT_COLS = [
  { id: "id", title: "Id", classStyle: {} },
  { id: "name", title: "Tat", classStyle: {} },
  { id: "created_by", title: "Created By", classStyle: {} },
  { id: "createdAt", title: "Created At", classStyle: {} },
];
export const ArchiveHeader = [
  { label: "Job Id", key: "id" },
  { label: "Job Ref. No.", key: "reference_no" },
  { label: "Job Type", key: "job_type" },
  { label: "Company Name", key: "company" },
  { label: "Client", key: "client" },
  { label: "% Complete", key: "job_status_perc" },
  { label: "Job Type", key: "job_type" },
  { label: "YearEnd Date", key: "yearend_date" },
  { label: "Job Status", key: "job_status" },
  { label: "Budget", key: "budget" },
  { label: "Actual", key: "actual" },
  { label: "Created By", key: "createdBy" },
  { label: "Job Creation Date", key: "job_start_date" },
  { label: "Deadline Date", key: "job_end_date" },
  { label: "Dispatch Date", key: "dispatchDate" },
  { label: "TAT", key: "job_tat" },
  { label: "Days Left", key: "days_left" },
  { label: "Job Manager (Dot)", key: "manager" },
];
export const HR_REPORT_COLUMN = {
  TALENT_ACQUISITION: "TalentAcquisition",
  ATTRITION_RATE: "AttritionRate",
};
export const MG_REPORT_COLUMN = {
  REVENUE: "Revenue",
  REVENUE_STREAM: "RevenueStream",
  ATTRITION_RATE: "AttritionRate",
};
export const HR_TABLE_COLUMN = {
  // TALENT ACQUISITION
  REQUIREMENTS: "Requirements",
  POSITION_CLOSED: "Position Closed",
  APPLICATIONS_RECEIVED: "Applications Received",
  CANDIDATES_REJECTED: "Candidates Rejected",
  CANDIDATES_INTERVIEWED: "Candidates Interviewed",
  JOINED: "Joined",
  EXIT: "Exit",
  // ATTRITION RATE
  SR_NO: "Sr No",
  EMP_CODE: "Emp Code",
  EMPLOYEE_NAME: "Employee Name",
  DESIGNATION: "Designation",
  DEPARTMENT: "Department",
  DOJ: "Emp DOJ",
  DATE_OF_RESIGNATION: "Date of Resignation",
  LAST_WORKING_DATE: "Last Working Date",
  REASON_OF_RESIGNATION: "Reason of Resignation",
  TOTAL_YR_OF_SERVICE: "Total Yr of Service",
};
export const HR_TABLE_COLUMN_ID = {
  // TALENT ACQUISITION
  REQUIREMENTS: "requirements",
  POSITION_CLOSED: "position_closed",
  APPLICATIONS_RECEIVED: "applications_received",
  CANDIDATES_REJECTED: "candidates_rejected",
  CANDIDATES_INTERVIEWED: "candidates_interviewed",
  JOINED: "joined",
  EXIT: "exit",
  // ATTRITION RATE
  SR_NO: "sr_no",
  EMP_CODE: "emp_code",
  EMPLOYEE_NAME: "employee_name",
  DESIGNATION: "designation",
  DEPARTMENT: "department",
  DOJ: "date_joining",
  DATE_OF_RESIGNATION: "date_reg",
  LAST_WORKING_DATE: "lwd",
  REASON_OF_RESIGNATION: "reason",

  TOTAL_YR_OF_SERVICE: "Exp",
};

export const TABEL_MONTH_CONSTANT = [
  { text: "Jan", value: "01" },

  { text: "Feb", value: "02" },

  { text: "Mar", value: "03" },

  { text: "Apr", value: "04" },

  { text: "May", value: "05" },

  { text: "Jun", value: "06" },

  { text: "Jul", value: "07" },

  { text: "Aug", value: "08" },

  { text: "Sep", value: "09" },

  { text: "Oct", value: "10" },

  { text: "Nov", value: "11" },

  { text: "Dec", value: "12" },
];
export const HR_REPORT_LABAL_CONSTANT = {
  MONTH: "month",
  YEAR: "year",
};

export const MANAGEMENT_REPORT_LABAL_CONSTANT = {
  MONTH: "month",
  YEAR: "year",
};

export const MG_REPORT_COLUMN_ID = {
  SERVICES: "job_name",
  JAN: "January",
  FEB: "February",
  MAR: "March",
  APR: "April",
  MAY: "May",
  JUN: "June",
  JULY: "July",
  AUG: "August",
  SEPT: "September",
  OCT: "October",
  NOV: "November",
  DEC: "December",
  TOTAL: "count",
};
export const MG_REPORT_COLUMN_LABEL = {
  SERVICES: "Services",
  JAN: "Jan",
  FEB: "Feb",
  MAR: "Mar",
  APR: "Apr",
  MAY: "May",
  JUN: "Jun",
  JULY: "July",
  AUG: "Aug",
  SEPT: "Sept",
  OCT: "Oct",
  NOV: "Nov",
  DEC: "Dec",
  TOTAL: "Total",
};

export const LEAVE_COLORS = [
  "#00B0F0",
  "#D60093",
  "#FF3300",
  "#FFC000",
  "#99cf3c",
  "#69a305",
  "#193256",
  "#193256",
  "#D60093",
  "#D60093",
];
export const LEAVE_COUNT = {
  "Loss Of Pay": 0,
  "Regularization":0,
  "Privileged Leave": 0,
  "Absent": 0,
  "Credit Leave": 0,
  "First Half Present with PL": 0,
  "Second Half Present with PL":0,
  "First Half Present with CL":0,
  "Second Half Present with CL":0,
};
export const LEAVE_LABELS = [
  "Holiday",
  "Credit Leave",
  "Leave Without Pay / Absent",
  "Regularization",
  "Privileged Leave",
  "Maternity Leave",
  "First Half Present with PL",
  "Second Half Present with PL",
  "First Half Present with CL",
  "Second Half Present with CL",
];

export const RECURRENCE_JOB_STATUS = {
  ORIGINAL: "Original",
  STOPPED: "Stopped"
};
export const RECURRENCE_BUTTON_LABEL = {
  STOP_RECURRENCE: "Stop Recurrence",
  STOPPED: "Stopped"
};

export const NON_RECURSION_DATE = "0000-00-00";

export const GENDER_LABEL = [
  "Male",
  "Female",
  "Others"
];
export const SORT_TYPE = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};
export const MONTH_NAMES = {
  "01" : "January",
  "02" : "February",
  "03" : "March",
  "04" : "April",
  "05" : "May",
  "06" : "June",
  "07" : "July",
  "08" : "August",
  "09" : "September",
  "10" : "October",
  "11" : "November",
  "12" : "December",
}
export const ATTENDANCE_HEADER  = [
  { label: "Date", key: "date" },
  { label: "Shift", key: "shift" },
  { label: "Day", key: "day" },
  { label: "Shift In", key: "shift_in" },
  { label: "Shift Out", key: "shift_out" },
  { label: "Total Hours", key: "duration" },
  { label: "Extra Hours", key: "extra_duration" },
  { label: "Late By", key: "late_by_duration" },
  { label: "Early By", key: "early_by_duration" },
  { label: "Status", key: "attendance_type" },
];