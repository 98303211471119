import React, { useEffect, useState } from "react";
// import { TimelineLite } from "gsap";
import { Icon } from "semantic-ui-react";
import { TimelineLite, Power2, gsap } from "gsap";
import OtpInput from "react-otp-input";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { ACC_LOCKED, NEW_OTP, RESEND_OTP, SEND_VERIFICATIONCODE, VERIFY_OTP } from "../../utils/labelContant";
gsap.registerPlugin([TimelineLite]);
export default function CodeVerification(props) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  // const [formValue, setFormValue] = useState({
  //   // role: "",
  //   email: props.email,
  //   // token: "",
  // });
  useEffect(() => {
    let recovery = new TimelineLite({ paused: true, smoothChildTiming: true, reversed: true, yoyo: true });
    recovery
      .fromTo(
        "#code_verification",
        { opacity: 0, ease: Power2.easeInOut, y: -50 },
        { opacity: 1, ease: Power2.easeInOut, y: 0 }
      )
      .play();
    return () => { };
  }, []);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) return;
    const timer = setInterval(() => {
      if (seconds === 0) {
        if (minutes > 0) {
          setMinutes((prev) => prev - 1);
          setSeconds(59);
        }
      } else {
        setSeconds((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes, seconds]);

  const getCodeBoxElement = (index) => {
    return document.getElementById("codeBox" + index);
  };
  const onKeyUpEvent = (index, event) => {
    const eventCode = event.which || event.keyCode;
    if (getCodeBoxElement(index).value.length === 1) {
      if (index !== 6) {
        getCodeBoxElement(index + 1).focus();
      } else {
        getCodeBoxElement(index).blur();
      }
    }
    if (eventCode === 8 && index !== 1) {
      getCodeBoxElement(index - 1).focus();
    }
  };
  const onFocusEvent = (index) => {
    for (let item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  };

  const loginTypeValue = () => {
    if (props.loginType === "DOT_TEAM") {
      return { role: 4 };
    }
    return {};
  };

  const validate = () => {
    if (isNaN(otp)) {
      setOtpError("Please enter a valid OTP")
    }
    else if (otp == '') {
      setOtpError('Please enter OTP')
    }
    else {
      let value = { email: props.email, token: otp, ...loginTypeValue() };
      axios
        .post("/forgetPassword/verifyToken", value, {
          headers: {},
        })
        .then((response) => {
          if (response.status === 200) {
            setEmailSuccess(response.data.message)
            props.onNext()
            props.otp(otp)
          }
          else {
            setOtpError(response.data.message)
          }
        })
        .catch((err) => {
          console.log(err?.response?.data?.err)
          if(err?.response?.data?.err == "Invalid Token"){
            setOtpError("Invalid Token");
          }
          else {
            setOtpError("OTP has expired. Please request a new one.")
          }
        })
        .finally(() => {
        });
    }
  }
  const resendOtp = async () => {
    try { const requestBody = {
      email: props.email,
      ...(props.loginType === "DOT_TEAM" && { role: 4 })
    };
    const response = await axios.post(SEND_VERIFICATIONCODE, requestBody);
      
      setOtp("");
      setOtpError("");
      setMinutes(2);
      setSeconds(0);
  
     
      toast.success(NEW_OTP);
    } catch (error) {
      toast.error(ACC_LOCKED);
    }
  };

  const onOtpChange = (e) => {
    setOtp(e);
    setOtpError('')
  };
  return (
    <div className="client-login recovery" id="code_verification">
  <p className="login-input-header">Code Verification</p>
  <p className="forgot-password-description">Enter Code sent to your Email address </p>
  <div className="input-form-control" name="formOtp" style={{ width: "auto" }}>
  <p className={`otp-timer ${minutes === 0 && seconds <= 10 ? "expiring" : ""}`}>
  OTP expires in: <strong>{minutes} min {seconds < 10 ? `0${seconds}` : seconds} sec</strong>
  </p>


    <label className="input-form-control-label" htmlFor="otp">Code</label>
    <OtpInput
      value={otp}
      onChange={onOtpChange}
      numInputs={6}
      shouldAutoFocus
      inputStyle={"inputStyle"}
      id={"otp"}
      // disabled={minutes === 0 && seconds === 0}
    />

    <div className="errorLabel">{otpError}</div>
    <div className="successLabel">{emailSuccess}</div>
  </div>

  <div className="resend-otp-container">
  <a
    href="#"
    onClick={(e) => {
      e.preventDefault();
      if (minutes === 0 && seconds === 0) resendOtp(); 
    }}
    className={`resend-otp-link ${minutes === 0 && seconds === 0 ? "active" : ""}`}
  >
    {RESEND_OTP}
  </a>
</div>
  <button
    className="login-type-submit"
    style={{
      height: window.innerHeight / 12,
      width: window.innerHeight / 12,
    }}
    disabled={minutes === 0 && seconds === 0}
    onClick={validate}
  >
    {VERIFY_OTP}
  </button>
</div>
  );
}
